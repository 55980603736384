import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import api from "./service/api";
import "./plugins/adaptive";
import "./styles/common.less";
import { myVanToast } from "./plugins/vant";

const app = createApp(App);
app.config.globalProperties.$Toast = myVanToast;
app.config.globalProperties.$api = api;
app.use(store).use(i18n).use(router).mount("#app");
