export default {
    public: {
        copy: "複製",
        copy_success: "複製成功",
    },
    home: {
        text1: "Web3應用代幣協議的未來",
        text2: "我們正在助力Web3海量用戶產品的騰飛，成為每一款Web3應用發揮創造力和自由的工具。",
        text3: "EEG 應用代幣協議",
        text4: "EEG應用代幣協議 - 為您的應用插上騰飛的翅膀。獎勵只是賦能的一部分，更是應用賦能！",
        text5: "Web3海量用戶有價值的產品時代來臨。諸多海量用戶的治理代幣，需要一個通用的應用代幣協議來實現雙幣聯動模型。EEG，應時而生，順勢而為，造勢而起，乘勢而上，勢不可擋！",
        text6: "EEG 實時數據",
        text7: "實時代幣數量",
        text8: "當前代幣價格(美元)",
        text9: "代幣總市值(美元)",
        text10: "合約地址：",
        text11: "去Gate.io購買 →",
        text12: "EEG 代幣模型",
        text13: "EEG代幣在各款應用內的產出消耗模型",
        text14: "EEG應用協議代幣100%歸屬於用戶。EEG應用協議接入各個合作夥伴後，EEG代幣在各款應用平台內動態產出和消耗，應用激勵產出代幣，應用內各項功能和增值服務消耗代幣轉入黑洞銷毀。產出沒有上限，銷毀沒有下限！實時動態變化。",
        text15: "產出 VS 銷毀",
        text16: "EEG的有趣未來",
        text17: "EEG產出機制",
        text18: "應用激勵產出代幣",
        text19: "EEG銷毀機制",
        text20: "應用內各項功能和增值服務消耗代幣轉入黑洞銷毀",
        text21: "EEG代幣的價值來自於EEG應用協議的生態價值和在各個合作夥伴的Web3應用內的增值服務和應用價值。",
        text22: "打造全球首個Web3應用代幣協議，徹底激活Web3海量用戶應用的爆發時代。",
        text23: "核心機制：",
        text24: "EEG產出 與 EEG消耗實時動態平衡，經常性的消耗會大於產出。海量用戶的增值服務消耗 VS 免費激勵的獎勵產出。",
        text25: "EEG 運作機制",
        text26: "Web3應用的未來",
        text27: "海量用戶",
        text28: "有價值的產品",
        text29: "雙幣模型的再創新！",
        text30: "治理代幣",
        text31: "應用代幣",
        text32: "您負責Web3應用的建設和治理代幣DAO",
        text33: "EEG負責Web3應用的用戶激勵和應用增值服務賦能",
        text34: "通過Web3讓現實生活更美好！",
        text35: "Web3應用",
        text36: "EEG應用協議",
        text37: "Web3應用",
        text38: "Web3應用",
        text39: "Web3應用",
        text40: "EEG應用代幣協議",
        text41: "應用獎勵激勵",
        text42: "應用內增值服務支付",
        text43: "交易",
        text44: "EEG應用代幣",
        text45: "獎勵激勵如何運作？",
        text46: "金豆",
        text47: "能量",
        text48: "鑽石",
        text49: "積分",
        text50: "其它",
        text51: "動態兌換",
        text52: "滿足最低額度後兌換uEEG，動態算法uEEG可隨時兌換為EEG及治理代幣等設定的可兌換的各種豐富代幣。",
        text53: "存入分配",
        text54: "存入，按所佔比例分配EEG。",
        text55: "Web3應用可定期提供一定數量的EEG（數量會隨著用戶的增加和DAO治理的增加而修改）放入收益池，用於 Staking 能量（鑽石，金豆，積分等等）的激勵。用戶可以存入能量等以獲得實時獎勵，存入的能量等將被銷毀。",
        text56: "EEG合作夥伴",
        text57: "重新定義價值互聯網 價值互聯網協議",
        text58: "免費模式發展海量用戶 GameFi 2.0模式成就SUPE",
    },
};
