//统一管理api
import { base_alchamy } from "./base"; // 导入接口域名列表
import { post } from "./tool"; // 导入http中创建的axios实例

// 获取coin价格
export const coinPrice = (params) => post(base_alchamy + "/query/price2", params);

export default {
    coinPrice,
};
