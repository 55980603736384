export default {
    public: {
        copy: "Copy",
        copy_success: "Copy success",
    },
    home: {
        text1: "The Future of Web3 Application Token Protocols",
        text2: "We are empowering the explosive growth of Web3 mass user products and becoming the tool for every Web3 application to unleash creativity and freedom.",
        text3: "EEG Application Token Protocol",
        text4: "EEG Application Token Protocol - Empower your application to take flight. Rewards are just part of the empowerment, but also application empowerment!",
        text5: "The era of valuable Web3 mass user products has arrived. The governance tokens of many mass users require a universal application token protocol to achieve a dual-token linkage model. EEG, born at the right time, seizing the opportunity, building momentum, riding the wave, unstoppable!",
        text6: "EEG Real-time Data",
        text7: "Real-time Token Quantity",
        text8: "Current Token Price (USD)",
        text9: "Token Total Market Value (USD)",
        text10: "Contract Address: ",
        text11: "Buy on Gate.io →",
        text12: "EEG Token Model",
        text13: "EEG token output and consumption model within various applications",
        text14: "EEG application protocol tokens are 100% owned by users. After EEG application protocol is integrated into various partners, EEG tokens are dynamically produced and consumed within various application platforms. Application incentives produce tokens, while various functions and value-added services consume tokens, which are then transferred to a black hole for destruction. There is no upper limit on production and no lower limit on destruction! It changes dynamically in real-time.",
        text15: "Output vs. Destruction",
        text16: "The Exciting Future of EEG",
        text17: "EEG Output Mechanism",
        text18: "Application incentives produce tokens",
        text19: "EEG Destruction Mechanism",
        text20: "Various functions and value-added services consume tokens, which are then transferred to a black hole for destruction",
        text21: "The value of EEG tokens comes from the ecological value of EEG application protocols and the value-added services and application value in various partner Web3 applications.",
        text22: "Create the world's first Web3 application token protocol and completely activate the explosive era of Web3 mass user applications.",
        text23: "Core Mechanism: ",
        text24: "EEG output and consumption are dynamically balanced in real-time, with consumption often exceeding production. Value-added services consumption of mass users vs. free incentive reward production.",
        text25: "EEG Operation Mechanism",
        text26: "The future of Web3 applications",
        text27: "Massive user base",
        text28: "Valuable products",
        text29: "Innovative dual-token model!",
        text30: "Governance token",
        text31: "Utility token",
        text32: "You are responsible for building and governing the DAO for Web3 applications",
        text33: "EEG is responsible for user incentives and value-added services for Web3 applications",
        text34: "Make real life better through Web3!",
        text35: "Web3 applications",
        text36: "EEG application protocol",
        text37: "Web3 applications",
        text38: "Web3 applications",
        text39: "Web3 applications",
        text40: "EEG application token protocol",
        text41: "Application reward incentives",
        text42: "Payment for value-added services within the application",
        text43: "Transactions",
        text44: "EEG application token",
        text45: "How do reward incentives work?",
        text46: "Gold beans",
        text47: "Energy",
        text48: "Diamonds",
        text49: "Points",
        text50: "Others",
        text51: "Dynamic exchange",
        text52: "After meeting the minimum amount, exchange uEEG tokens dynamically for various rich tokens that can be exchanged, such as EEG and governance tokens, as set by the dynamic algorithm of uEEG.",
        text53: "Allocation of deposits",
        text54: "EEG will be allocated according to the percentage deposited",
        text55: "Web3 applications can periodically provide a certain amount of EEG (the amount will be adjusted as the number of users and DAO governance increases) into the income pool for incentivizing Staking Energy(diamonds, gold beans, points, etc.). Users can deposit Energy to receive real-time rewards, and the deposited Energy will be destroyed.",
        text56: "EEG partners",
        text57: "Redefine the value internet - value internet protocol",
        text58: "Develop a massive user base through a free model and achieve success of SUPE through GameFi 2.0 model.",
    },
};
