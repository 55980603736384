export default {
    public: {
        copy: "复制",
        copy_success: "复制成功",
    },
    home: {
        text1: "Web3应用代币协议的未来",
        text2: "我们正在助力Web3海量用户产品的腾飞，成为每一款Web3应用发挥创造力和自由的工具。",
        text3: "EEG 应用代币协议",
        text4: "EEG应用代币协议 - 为您的应用插上腾飞的翅膀。奖励只是赋能的一部分，更是应用赋能！",
        text5: "Web3海量用户有价值的产品时代来临。诸多海量用户的治理代币，需要一个通用的应用代币协议来实现双币联动模型。EEG，应时而生，顺势而为，造势而起，乘势而上，势不可挡！",
        text6: "EEG 实时数据",
        text7: "实时代币数量",
        text8: "当前代币价格(美元)",
        text9: "代币总市值(美元)",
        text10: "合约地址：",
        text11: "去Gate.io购买 →",
        text12: "EEG 代币模型",
        text13: "EEG代币在各款应用内的产出消耗模型",
        text14: "EEG应用协议代币100%归属于用户。EEG应用协议接入各个合作伙伴后，EEG代币在各款应用平台内动态产出和消耗，应用激励产出代币，应用内各项功能和增值服务消耗代币转入黑洞销毁。产出没有上限，销毁没有下限！实时动态变化。",
        text15: "产出 VS 销毁",
        text16: "EEG的有趣未来",
        text17: "EEG产出机制",
        text18: "应用激励产出代币",
        text19: "EEG销毁机制",
        text20: "应用内各项功能和增值服务消耗代币转入黑洞销毁",
        text21: "EEG代币的价值来自于EEG应用协议的生态价值和在各个合作伙伴的Web3应用内的增值服务和应用价值。",
        text22: "打造全球首个Web3应用代币协议，彻底激活Web3海量用户应用的爆发时代。",
        text23: "核心机制：",
        text24: "EEG产出 与 EEG消耗实时动态平衡，经常性的消耗会大于产出。海量用户的增值服务消耗 VS 免费激励的奖励产出。",
        text25: "EEG 运作机制",
        text26: "Web3应用的未来",
        text27: "海量用户",
        text28: "有价值的产品",
        text29: "双币模型的再创新！",
        text30: "治理代币",
        text31: "应用代币",
        text32: "您负责Web3应用的建设和治理代币DAO",
        text33: "EEG负责Web3应用的用户激励和应用增值服务赋能",
        text34: "通过Web3让现实生活更美好！",
        text35: "Web3应用",
        text36: "EEG应用协议",
        text37: "Web3应用",
        text38: "Web3应用",
        text39: "Web3应用",
        text40: "EEG应用代币协议",
        text41: "应用奖励激励",
        text42: "应用内增值服务支付",
        text43: "交易",
        text44: "EEG应用代币",
        text45: "奖励激励如何运作？",
        text46: "金豆",
        text47: "能量",
        text48: "钻石",
        text49: "积分",
        text50: "其它",
        text51: "动态兑换",
        text52: "满足最低额度后兑换uEEG，动态算法uEEG可随时兑换为EEG及治理代币等设定的可兑换的各种丰富代币。",
        text53: "存入分配",
        text54: "存入，按所占比例分配EEG。",
        text55: "Web3应用可定期提供一定数量的EEG（数量会随着用户的增加和DAO治理的增加而修改）放入收益池，用于 Staking 能量（钻石，金豆，积分等等）的激励。用户可以存入能量等以获得实时奖励，存入的能量等将被销毁。",
        text56: "EEG合作伙伴",
        text57: "重新定义价值互联网 价值互联网协议",
        text58: "免费模式发展海量用户 GameFi 2.0模式成就SUPE",
    },
};
